import React, { Fragment, useEffect, lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStorageValue, removeStorageValue, setStorageValue } from './Services/LocalStorageServices';
import { useDispatch, useSelector } from 'react-redux';
import { IS_AUTH } from './Redux/Types';
import { Toaster } from 'react-hot-toast';
import { emitter } from './Utils/Emmiter';
import { InlineWidget, PopupWidget } from 'react-calendly';


// Lazy-loaded Components
const AuthRoutes = lazy(() => import('./Routes/AuthRoutes'));
const HomeRoute = lazy(() => import('./Routes/HomeRoute'));

const App = () => {
  const location = useLocation();
  var currentPathname = location.pathname;



  const { isAuth } = useSelector((state) => state.signupState)
  const token = getStorageValue('accessToken')
  const dispatch = useDispatch()


  console.log(token, 'tokentokentoken');

  // console.log(isAuth);
  const renderComponent = () => {
    // alert('hee')
    if (!isAuth) {
      return <AuthRoutes />;
    }

    if (isAuth) {
      return <HomeRoute />;
    }
  };



  useEffect(() => {
    // Function to extract token from URL and store it in local storage
    const storeTokenFromUrl = () => {
      const params = new URLSearchParams(location.search);
      const isPublic = new URLSearchParams(location.search);
      // const isPublic = params.get('public'); // Extract 'public' parameter
      const utmSource = params.get('utm_source'); // Extract 'utm_source'
      const utmMedium = params.get('utm_medium'); // Extract 'utm_medium'
      const utmCampaign = params.get('utm_campaign'); // Extract 'utm_medium'

      console.log('utm_source:', utmSource, 'utm_medium:', utmMedium, 'params:', params, 'isPublic:', isPublic, 'utm_campaign:', utmCampaign);
      const isPublicStatus = isPublic.get('public');
 
      if (isPublicStatus) {
        localStorage.clear();
        setTimeout(() => {
          setStorageValue('utm_source', utmSource)
          setStorageValue('utm_medium', utmMedium)
          setStorageValue('utm_campaign', utmCampaign)
          dispatch({
            type: IS_AUTH,
            payload: false
          })
          window.location.href = '/Register'
        }, 100);


      }
      const toke = params.get('token');
      console.log('toRaw', toke);
      if (toke) {
        setStorageValue('accessToken', toke);
        setTimeout(() => {
          dispatch({
            type: IS_AUTH,
            payload: true
          })
        }, 1000);

      }
    };

    storeTokenFromUrl();
  }, []);

  useEffect(() => {
    if (token) {
      dispatch({
        type: IS_AUTH,
        payload: true
      })
    }
    else {
      dispatch({
        type: IS_AUTH,
        payload: false
      })

    }
    // removeStorageValue('accessToken')
  }, [isAuth])


  useEffect(() => {
    emitter.on("logout", () => {
      window.location.href = '/dashboard'
      // navigate('/')
    });
  }, [isAuth]);



  return (
    <div className=' h-screen  mx-auto bg-background dark:bg-gray-900 '>
      <Suspense fallback={<div className='h-screen w-screen bg-red-50'>Loading...</div>}>


        <ToastContainer />
        <Toaster />
        {renderComponent()}
      </Suspense>



      {/* <SlotBookModal/> */}
      {/* <SlotErrorModal/> */}
      {/* <ErrorModal/> */}
      {/* <SucessActionModal/> */}
      {/* <InformationModal/> */}
    </div>
  );
};

export default App;



// // App.js
// import React, { useEffect, useState } from 'react';
// import io from 'socket.io-client';

// const socket = io('http://localhost:4000');

// const App = () => {
//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState('');

//   useEffect(() => {
//     socket.on('message', (message) => {
//       setMessages((prevMessages) => [...prevMessages, message]);
//     });

//     // Cleanup on component unmount
//     return () => {
//       socket.off('message');
//     };
//   }, []);

//   const sendMessage = () => {
//     socket.emit('message', input);
//     setInput('');
//   };

//   return (
//     <div>
//       <h1>Socket.io Chat</h1>
//       <div>
//         {messages.map((msg, index) => (
//           <div key={index}>{msg}</div>
//         ))}
//       </div>
//       <input
//         type="text"
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         onKeyPress={(e) => {
//           if (e.key === 'Enter') {
//             sendMessage();
//           }
//         }}
//       />
//       <button onClick={sendMessage}>Send</button>
//     </div>
//   );
// };

// export default App;
