import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const userBookingSlot = createAsyncThunk(
  "userBookingSlot",
  async (payload) => {
    try {
      let url = `${BASE_URL}/user/user-booking-slot-list`;
      const res = await axios.post(url, payload);
      return res.data;
    } catch (error) {
      handleError(error)
      throw error;
    }
  }
);


export const allAvialbaleUserBookingSlot = createAsyncThunk(
  "allAvialbaleUserBookingSlot",
  async (payload) => {
    try {
      let queryParams = [];

      queryParams.push(`selectedStartTime=${payload?.selectedStartTime}`);
      queryParams.push(`selectedEndTime=${payload?.selectedEndTime}`);
      queryParams.push(`teacherType=${payload?.teacherType}`);
      queryParams.push(`date=${payload?.date}`);
      queryParams.push(`classType=${payload?.classType}`);

      let url = `${BASE_URL}/session/all-available-session-list?${queryParams.join('&')}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error)
      throw error;
    }
  }
);

export const bookStudentSlot = createAsyncThunk(
  "bookStudentSlot",
  async (payload) => {
    console.log(payload);
    try {
      let url = `${BASE_URL}/session/book-student-slot`;
      const res = await axios.post(url, payload);
      console.log(res);
      return res.data;
    } catch (error) {
      return error
      // handleError(error)
      // console.log(error);
      // throw error;
    }
  }
);


export const bookDemoSlotServices = createAsyncThunk(
  "bookDemoSlotServices",
  async (payload) => {
    console.log(payload);
    try {
      let url = `${BASE_URL}/session/book-demo`;
      const res = await axios.post(url, payload);
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      handleError(error);
      throw error;
    }
  }
);



export const teacherEarliestTimeStampServices = createAsyncThunk(
  "teacherEarliestTimeStampServices",
  async (params) => {
    try {
      let url = `${BASE_URL}/session/earliest-available-session-timestamp?teacherType=${params?.teacherType}&classType=${params?.classType}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error)
      throw error;
    }
  }
);
